import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import {
  Text,
  Flex,
  Box,
  Image
} from 'rebass'

import avocado from '../images/avocado_404.svg'

import Container from '../components/container'
import BottomHero from '../components/bottomHero'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box style={{ textAlign: 'center' }} mx='auto'>
          <Text py={2} fontWeight='bold' fontSize={5}>Page not found</Text>
          <Text textAlign='center'><Image width='200' src={avocado} alt='Avocado figure'/></Text>
          <Text textAlign='center' py={2}>You have reached a page that does not exist. Click <Link color='gray' to='/'>here</Link> to return home.</Text>
        </Box>
      </Flex>
    </Container>
    <BottomHero bg='lightGray' />
  </Layout>
)

export default NotFoundPage
